import { POLYGON_MODULES } from "@/const/general";
import { CAMERAS_MODULES, TRANSPORT_TYPES } from "@/const/search";

export default function groupMarkers(markers: Marker, showOnly: ValueLabel[]) {
  return Array.isArray(markers) && markers.length > 0
    ? markers.reduce(
        (prev, curr) =>
          CAMERAS_MODULES.includes(curr.type)
            ? { ...prev, cameras: [...prev.cameras, curr] }
            : POLYGON_MODULES.some((m) => m.value === curr.type) ||
              [
                "ai_satellite",
                "disasters",
                "stores",
                "trestle",
                "airports",
                "stadium",
                "waze",
                "police",
                "amtrak",
                "incidents",
                "uploaded_items",
                "picarta",
                "photo_geolocation",
                ...TRANSPORT_TYPES,
              ].includes(curr.type) ||
              showOnly.some((opt) => opt.value === curr.type)
            ? { ...prev, regular: [...prev.regular, curr] }
            : prev,
        { regular: [], cameras: [] }
      )
    : { regular: [], cameras: [] };
}
