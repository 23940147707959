const transportation = [
  { value: "planes", label: "Planes" },
  { value: "vessel", label: "Vessel" },
  { value: "waze", label: "Waze" },
  { value: "amtrak", label: "Amtrak" },
  { value: "incidents", label: "Incidents" },
  { value: "police", label: "Police" },
] as Option<any>[];

export default transportation;
