import { useContext, useEffect, useState } from "react";
import { ICON_TYPES, MODULES } from "../../../const/search";
import { ActiveRecordContext } from "../../../providers/stats/ActiveRecordProvider";
import { faList } from "@fortawesome/free-solid-svg-icons";
import TimestampIcon from "../../../const/search/imgs/timestamp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SINGLE_ATTRIBUTE_EXCLUSIONS } from "../../../const/single-item";
import { Link, useParams } from "react-router-dom";
import isImgUrl from "../../../utils/isImgUrl";
import { Card, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { List, SquareArrowOutUpRight } from "lucide-react";

export default function ActiveRecord() {
  const [isUrlAnImage, setIsUrlAnImage] = useState<boolean | undefined>();
  const { activeRecord } = useContext(ActiveRecordContext);
  const { workspace_id } = useParams();

  useEffect(() => {
    if (!activeRecord || !activeRecord.photo_url) return;
    (async () => {
      const isAnImage = await isImgUrl(activeRecord.photo_url);
      setIsUrlAnImage(isAnImage);
    })();
  }, [activeRecord]);

  if (!activeRecord) return;
  const icon = ICON_TYPES[activeRecord.type as keyof typeof ICON_TYPES];
  const item = MODULES.find((item) => item.value === activeRecord.type);
  const date = new Date(activeRecord.timestamp);
  const dateTime =
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  const color = item?.color || "#376187";
  const activeRecordProperties = Object.keys(activeRecord).filter((key) => {
    const prop = key as keyof typeof activeRecord;
    const isExcluded =
      SINGLE_ATTRIBUTE_EXCLUSIONS[
        activeRecord.type as keyof typeof SINGLE_ATTRIBUTE_EXCLUSIONS
      ].includes(key);
    return (
      !isExcluded &&
      typeof activeRecord[prop] !== "object" &&
      !Array.isArray(activeRecord[prop]) &&
      !!activeRecord[prop]
    );
  });

  return (
    <Card className="p-4 w-max rounded grid grid-cols-2 gap-4 max-w-[34rem] h-full">
      <div className="flex items-center gap-4 justify-between col-span-2">
        <div className="flex items-center gap-3 h-max">
          <div className="w-5 h-5 rounded overflow-hidden relative">
            {icon && (
              <img
                className="absolute inset-0 w-full h-full object-cover object-center"
                src={icon}
                alt=""
              />
            )}
          </div>
          <CardTitle className="text-lg">
            {item?.label || activeRecord.type}
          </CardTitle>
        </div>
        <div className="flex items-center gap-3 h-max">
          <FontAwesomeIcon className="h-3" icon={faList} color={color} />
          <span className="text-[var(--text-color)] opacity-60 text-sm">
            Details
          </span>
        </div>
      </div>
      {activeRecord.photo_url && (
        <div className="rounded overflow-hidden relative bg-[var(--body-color)]">
          {isUrlAnImage ? (
            <img
              className="absolute inset-0 w-full h-full object-contain"
              src={activeRecord.photo_url}
              alt=""
            />
          ) : (
            <video
              className="absolute inset-0 w-full h-full object-contain"
              src={activeRecord.photo_url}
              controls
              controlsList="nofullscreen"
            ></video>
          )}
        </div>
      )}
      <div
        style={{ color }}
        className={`rounded px-3 py-2 bg-[#D9D9D9]/10 flex-1 hide-scroll gap-x-4 gap-y-2 overflow-x-hidden overflow-y-auto w-full ${
          activeRecord.photo_url
            ? "flex flex-col"
            : "col-span-2 grid grid-cols-2"
        }`}
      >
        {activeRecordProperties.map((key) => (
          <PropertyRef
            name={key}
            value={String(activeRecord[key as keyof typeof activeRecord])}
            key={key}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 gap-3">
        <Button asChild>
          <Link
            to={`/${workspace_id}/module/${activeRecord.type}/item/${activeRecord.id}`}
          >
            <List size={14} />
            Details
          </Link>
        </Button>

        <Button variant="outline" asChild>
          <Link to={activeRecord.url}>
            <SquareArrowOutUpRight size={14} />
            Source
          </Link>
        </Button>
      </div>
      <div
        style={{ color }}
        className="rounded px-3 py-2 bg-[#D9D9D9]/10 flex items-center justify-between gap-8 group min-w-max"
      >
        <div className="flex items-center gap-2">
          <div style={{ stroke: color }} className="h-4">
            <TimestampIcon />
          </div>
          <span className="text-[var(--text-color)] opacity-60 text-sm">
            Timestamp
          </span>
        </div>
        <span className="text-base text-[var(--text-color)]">{dateTime}</span>
      </div>
    </Card>
  );
}

function PropertyRef({ name, value }: { name: string; value: string }) {
  return (
    <div>
      <p className="text-[var(--text-color)] text-base break-words w-full opacity-80">
        {name}:{" "}
      </p>
      <p className="text-[var(--text-color)] text-base break-words w-full line-clamp-4">
        {value}
      </p>
    </div>
  );
}
