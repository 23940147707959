import { ICON_TYPES } from "@/const/search";
import ObjectRef from "../tile-items/ObjectRef";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Button } from "../ui/button";
import useMap from "@/hooks/useMap";

type Props = {
  title: string;
  objects: SearchObject[];
  name: string;
};

export default function TypeAccordion({ title, name, objects }: Props) {
  const { map } = useMap();
  return (
    <AccordionItem className="px-4" value={name}>
      <AccordionTrigger>
        <img
          className="h-5 w-5 object-contain"
          src={ICON_TYPES[name as keyof typeof ICON_TYPES]}
          alt=""
        />
        <span>
          {title
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ")}
        </span>
        <span className="opacity-60 font-normal">{objects.length}</span>
      </AccordionTrigger>
      <AccordionContent>
        {objects.length > 0 ? (
          <div
            style={{
              gridTemplateColumns: `repeat(auto-fill,minmax(360px,1fr))`,
            }}
            className="grid gap-2"
          >
            {objects.map((obj) => (
              <ObjectRef
                {...obj}
                showTypeIcon
                menuChildren={
                  <Button
                    className="-ml-1.5"
                    variant="outline"
                    onClick={() => {
                      if (!map) return;
                      map.panTo(obj.location);
                      map.setZoom(20);
                    }}
                    key={name + obj.id + "zoom"}
                  >
                    Zoom on map
                  </Button>
                }
                key={name + obj.id}
              />
            ))}
          </div>
        ) : (
          <div className="py-2">
            <p className="text-font text-sm opacity-75 text-center">
              No results found
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
