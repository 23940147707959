import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LocationRef from "../components/database/LocationRef";
import { deleteCoordinates } from "../utils/search/lib";
import Popup from "../components/Popup";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useTutorial } from "../providers/tutorial";
import StepPopup from "../components/tutorial/step";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

import Toast from "@/components/Toast";
import useAreas from "@/lib/database/areas";

export default function Database() {
  const navigate = useNavigate();
  const { step, changeStep, isFinished } = useTutorial();
  const { getAccessTokenSilently } = useAuth0();
  const [deletedItem, setDeletedItem] = useState<any>(null);
  const { data: items, isLoading, mutate, error } = useAreas();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="var(--text-color)" />
      </div>
    );
  }
  if (error)
    return (
      <div className="w-full h-screen flex items-center justify-center text-font">
        Error: {error.message}
      </div>
    );

  async function onDelete() {
    if (!deletedItem) return;
    setIsDeleteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const status = await deleteCoordinates(token, deletedItem.id);
      if (status && status < 300) {
        mutate();
        setDeletedItem(null);
      }
    } catch (e) {
      toast.custom((t) => <Toast {...t} title="There was an error" />);
    } finally {
      setIsDeleteLoading(false);
    }
  }

  const tutorialVisible = !isFinished && step === 0;
  const tutorialNextVisible = !isFinished && step === 3;

  return (
    <section className="h-full transition-all flex flex-col gap-4 w-full p-8">
      {items && items.length > 0 && !tutorialVisible ? (
        <>
          <div className="flex items-center gap-4 justify-between">
            <h1 className="text-[var(--text-color)] text-3xl font-medium">
              Recent Searches
            </h1>
            <Button variant="outline" asChild>
              <Link to="/add-location">
                <Plus size={16} />
                Add New
              </Link>
            </Button>
          </div>
          <div className="flex flex-col gap-4 xl:gap-8 xl:grid grid-cols-[repeat(auto-fill,minmax(550px,1fr))] items">
            {tutorialNextVisible ? (
              <LocationRef
                {...items[0]}
                setDeletedItem={setDeletedItem}
                isFirstChild
              />
            ) : (
              items.map((item: any) => (
                <LocationRef
                  {...item}
                  setDeletedItem={setDeletedItem}
                  key={item.id}
                />
              ))
            )}
          </div>
        </>
      ) : (
        <div className="flex-1 flex flex-col gap-4 justify-center items-center relative">
          <h2 className="text-2xl text-[var(--primary-color2)]">
            No coordinates have been searched yet!
          </h2>
          <div className="relative z-20">
            <Button
              onClick={() => {
                !isFinished && changeStep();
                navigate("/add-location");
              }}
            >
              Search for location
            </Button>
            {tutorialVisible && (
              <StepPopup
                title="Adding location"
                paragraph="In order for you to be able to search objects, first you'll need to add location and define the bounds."
                actionText="Click on the button to add a location"
              />
            )}
          </div>
          {tutorialVisible && (
            <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-10" />
          )}
        </div>
      )}
      {!!deletedItem && (
        <Popup
          isLoading={isDeleteLoading}
          title={
            <span>
              Are you sure you want to delete the{" "}
              <span className="text-[var(--primary-color2)]">
                {deletedItem ? deletedItem.title : ""}
              </span>{" "}
              coordinates?
            </span>
          }
          paragraph="This action cannot be undone. This will permanently delete the area and remove all related data."
          onClose={() => setDeletedItem(null)}
          onSubmit={onDelete}
        />
      )}
      {/* <Dialog>
        <DialogTrigger>Eaaefeaf</DialogTrigger>
        <DialogContent>
          <DialogTitle>Test</DialogTitle>
        </DialogContent>
      </Dialog> */}
    </section>
  );
}
