import { Card, CardTitle } from "@/components/ui/card";
import { ICON_TYPES, MODULES } from "../../../const/search";
import List from "../ui/list";

export default function StravaStatsList({ distance, names }: StravaStats) {
  const { color } = MODULES.find((item) => item.value === "strava");
  return (
    <Card className="p-4 w-max rounded self-stretch flex gap-4 flex-row">
      <div className="flex flex-col">
        <div className="flex gap-4">
          <div className="w-11 h-11 rounded overflow-hidden relative">
            <img
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={ICON_TYPES["strava"]}
              alt=""
            />
          </div>
          <div>
            <CardTitle className="text-xl">Strava</CardTitle>
            <p className="text-[var(--text-color)] opacity-80 text-lg">
              User activity
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-end">
          <div className="space-y-2">
            <div className="flex items-center gap-1.5">
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clip-rule="evenodd"
                  d="M8.25 0C12.799 0 16.5 3.729 16.5 8.313C16.5 13.948 10.044 19.5 8.25 19.5C6.456 19.5 0 13.948 0 8.313C0 3.729 3.701 0 8.25 0ZM8.25 1.5C4.528 1.5 1.5 4.557 1.5 8.313C1.5 13.092 7.124 17.748 8.25 17.996C9.376 17.747 15 13.091 15 8.313C15 4.557 11.972 1.5 8.25 1.5ZM8.251 5C10.043 5 11.501 6.458 11.501 8.251C11.501 10.043 10.043 11.5 8.251 11.5C6.459 11.5 5.001 10.043 5.001 8.251C5.001 6.458 6.459 5 8.251 5ZM8.251 6.5C7.286 6.5 6.501 7.285 6.501 8.251C6.501 9.216 7.286 10 8.251 10C9.216 10 10.001 9.216 10.001 8.251C10.001 7.285 9.216 6.5 8.251 6.5Z"
                  fill="#F44903"
                />
              </svg>
              <h4 className="text-[var(--text-color)] opacity-40 text-base mb-0.5">
                Average distance
              </h4>
            </div>
            <div className="rounded py-2 px-4 bg-[#D9D9D9]/10 space-y-2">
              <p className="text-[var(--text-color)] text-xl w-full text-center">
                {distance.toFixed(2)} km
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="self-end">
        <List title="Names" records={names} color={color} />
      </div>
    </Card>
  );
}
