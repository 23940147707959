import { Bar, BarChart, CartesianGrid, Cell, YAxis } from "recharts";

// const CHART_COLORS = ["#FF5D62", "#FFA666", "#F7AD93"];

export default function PriceChart({
  price,
  color,
}: Price & { color: string }) {
  const chartData = price.sort((a, b) => a - b).map((num) => ({ value: num }));
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1.5">
        <svg
          width="16"
          height="15"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M14.642 0C17.949 0 20.639 2.69 20.639 5.998V13.175C20.639 16.482 17.949 19.173 14.642 19.173H5.998C2.691 19.173 0 16.482 0 13.175V5.998C0 2.69 2.691 0 5.998 0H14.642ZM14.642 1.5H5.998C3.518 1.5 1.5 3.518 1.5 5.998V13.175C1.5 15.655 3.518 17.673 5.998 17.673H14.642C17.122 17.673 19.139 15.655 19.139 13.175V12.895L15.8411 12.8956C13.9441 12.8956 12.4001 11.3526 12.3991 9.4566C12.3991 7.5586 13.9431 6.0146 15.8411 6.0136L19.139 6.013V5.998C19.139 3.518 17.122 1.5 14.642 1.5ZM19.139 7.513L15.8411 7.5136C14.7701 7.5146 13.8991 8.3856 13.8991 9.4556C13.8991 10.5246 14.7711 11.3956 15.8411 11.3956L19.139 11.395V7.513ZM16.2987 8.6436C16.7127 8.6436 17.0487 8.9796 17.0487 9.3936C17.0487 9.8076 16.7127 10.1436 16.2987 10.1436H15.9867C15.5727 10.1436 15.2367 9.8076 15.2367 9.3936C15.2367 8.9796 15.5727 8.6436 15.9867 8.6436H16.2987ZM10.685 4.5381C11.099 4.5381 11.435 4.8741 11.435 5.2881C11.435 5.7021 11.099 6.0381 10.685 6.0381H5.286C4.872 6.0381 4.536 5.7021 4.536 5.2881C4.536 4.8741 4.872 4.5381 5.286 4.5381H10.685Z"
            fill={color}
          />
        </svg>
        <h4 className="text-[var(--text-color)] opacity-40 text-base mb-0.5">
          Price
        </h4>
      </div>
      <div className="rounded pt-4 pb-2 px-4 bg-[#D9D9D9]/10 flex-1 flex flex-col gap-1 justify-center">
        <BarChart width={148} height={132} data={chartData} barSize={8}>
          <CartesianGrid
            vertical={false}
            stroke="var(--text-color)"
            strokeOpacity={0.25}
          />
          <YAxis
            width={48}
            dataKey="value"
            stroke="var(--text-color)"
            fontSize={12}
            axisLine={false}
            includeHidden
            tickLine={false}
            tickFormatter={(value) => `$${parseInt(value)}`}
            ticks={chartData.map((item) => item.value)}
          />
          <Bar dataKey="value" fill="#FF5A60" radius={255}>
            {chartData.map(({ value }, i) => (
              <Cell
                // fill={CHART_COLORS[i % CHART_COLORS.length]}
                fill={color}
                key={value + i}
              />
            ))}
          </Bar>
        </BarChart>
      </div>
    </div>
  );
}
