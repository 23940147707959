import { cn } from "@/utils/cn";
import { createContext, useContext, useEffect, useState } from "react";

type ThemeContextType = {
  isDark: boolean;
  changeTheme: () => void;
};

function getSessionStorageOrDefaultTheme() {
  const stored = localStorage.getItem("theme-dark");
  if (!stored) {
    return true;
  }
  return stored === "true";
}

export const ThemeContext = createContext<ThemeContextType>(null!);

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  return theme;
};

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isDark, setIsDark] = useState(getSessionStorageOrDefaultTheme());

  const changeTheme = () => {
    setIsDark((prev) => !prev);
    localStorage.setItem("theme-dark", String(!isDark));
  };

  useEffect(() => {
    isDark
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, [isDark]);

  return (
    <ThemeContext.Provider value={{ isDark, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
