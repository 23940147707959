import { RadioBar } from "@/components/search/radio";
import { ScrollArea } from "@/components/ui/scroll-area";
import useDailyDashboard from "@/lib/daily-dashboard";
import { Pause, Play, Radio } from "lucide-react";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

export default function Radios({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const dashboardContainer = document.getElementById("dashboard-container");
  const [selectedRadio, setSelectedRadio] = useState<RadioResult | null>(null);
  const { data } = useDailyDashboard(workspace_id, date);

  return (
    <>
      {dashboardContainer &&
        selectedRadio &&
        createPortal(
          <RadioBar
            {...selectedRadio}
            onClose={() => setSelectedRadio(null)}
          />,
          dashboardContainer
        )}
      <div className="flex-1 pt-4 border-t dark:border-[#1F242F] flex flex-col">
        <h3 className="text-xl text-font opacity-75 mb-1 px-6">Radios</h3>
        <p className="mb-1 px-6">
          <strong className="text-4xl font-medium text-font">
            {data?.objects.radio ? data.objects.radio.length : 0}
          </strong>
        </p>
        {data?.objects.radio && data.objects.radio.length > 0 ? (
          <div className="flex-1 relative">
            <div className="absolute inset-0 h-full w-full">
              <ScrollArea className="h-full px-6">
                {data.objects.radio.map((radio) => (
                  <RadioRef
                    {...radio}
                    isPlaying={selectedRadio?.id === radio.id}
                    onPlay={() => setSelectedRadio(radio)}
                    key={radio.id}
                  />
                ))}
              </ScrollArea>
            </div>
          </div>
        ) : (
          <div className="flex-1 justify-center items-center flex flex-col gap-2 text-font opacity-80">
            <Radio size={18} />
            <p className="text-center text-base">No radios found</p>
          </div>
        )}
      </div>
    </>
  );
}

const RadioRef = ({ name, isPlaying, onPlay }: RadioResult) => (
  <div className="flex items-center gap-2 py-1 last:pb-4">
    <button
      onClick={onPlay}
      className="border dark:border-[#1F242F] h-9 w-9 shrink-0 rounded-full flex items-center justify-center"
    >
      {isPlaying ? (
        <Pause size={16} className="text-font" />
      ) : (
        <Play size={16} className="text-font" />
      )}
    </button>
    <h5 className="text-font text-lg">{name}</h5>
  </div>
);
