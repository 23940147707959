"use client";

import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandItem,
  CommandEmpty,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/utils/cn";
import { Command as CommandPrimitive } from "cmdk";
import { X as RemoveIcon, Check, SlidersHorizontal } from "lucide-react";
import React, {
  KeyboardEvent,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Button } from "./button";
import useOutsideObserver from "@/hooks/useOutsideObserver";

type MultiSelectProps = {
  values: ValueLabel[];
  onSelectionChange: (values: ValueLabel[]) => void;
  loop?: boolean;
  renderButton?: boolean;
} & React.ComponentPropsWithoutRef<typeof CommandPrimitive>;

interface MultiSelectContextProps {
  selected: ValueLabel[];
  onSelectionChange: (value: any) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  renderButton?: boolean;
}

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null);

const useMultiSelect = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error("useMultiSelect must be used within MultiSelectProvider");
  }
  return context;
};

const MultiSelect = ({
  values,
  onSelectionChange,
  loop = false,
  className,
  children,
  dir,
  renderButton,
  ...props
}: MultiSelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  useOutsideObserver(ref, () => renderButton && setOpen(false));

  const onValueChangeHandler = useCallback(
    (val: ValueLabel) => {
      if (values.findIndex((opt) => opt.value === val.value) !== -1) {
        onSelectionChange(values.filter((item) => item.value !== val.value));
      } else {
        onSelectionChange([...values, val]);
      }
    },
    [values]
  );

  // TODO : change from else if use to switch case statement

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const moveNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(
          nextIndex > values.length - 1 ? (loop ? 0 : -1) : nextIndex
        );
      };

      const movePrev = () => {
        const prevIndex = activeIndex - 1;
        setActiveIndex(prevIndex < 0 ? values.length - 1 : prevIndex);
      };

      if ((e.key === "Backspace" || e.key === "Delete") && values.length > 0) {
        if (inputValue.length === 0) {
          if (activeIndex !== -1 && activeIndex < values.length) {
            onSelectionChange(
              values.filter((item) => item.value !== values[activeIndex].value)
            );
            const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
            setActiveIndex(newIndex);
          } else {
            onSelectionChange(
              values.filter(
                (item) => item.value !== values[values.length - 1].value
              )
            );
          }
        }
      } else if (e.key === "Enter") {
        setOpen(true);
      } else if (e.key === "Escape") {
        if (activeIndex !== -1) {
          setActiveIndex(-1);
        } else {
          setOpen(false);
        }
      } else if (dir === "rtl") {
        if (e.key === "ArrowRight") {
          movePrev();
        } else if (e.key === "ArrowLeft" && (activeIndex !== -1 || loop)) {
          moveNext();
        }
      } else {
        if (e.key === "ArrowLeft") {
          movePrev();
        } else if (e.key === "ArrowRight" && (activeIndex !== -1 || loop)) {
          moveNext();
        }
      }
    },
    [values, inputValue, activeIndex, loop]
  );

  return (
    <MultiSelectContext.Provider
      value={{
        selected: values,
        onSelectionChange: onValueChangeHandler,
        open,
        setOpen,
        inputValue,
        setInputValue,
        activeIndex,
        setActiveIndex,
        renderButton,
      }}
    >
      <Command
        ref={ref}
        onKeyDown={handleKeyDown}
        className={cn(
          "overflow-visible bg-transparent flex flex-col",
          className
        )}
        dir={dir}
        {...props}
      >
        {children}
      </Command>
    </MultiSelectContext.Provider>
  );
};

const MultiSelectTrigger = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    valuesClassName?: string;
  }
>(({ className, children, valuesClassName, ...props }, ref) => {
  const { selected, setOpen, renderButton, onSelectionChange, activeIndex } =
    useMultiSelect();

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        "flex flex-wrap gap-y-2 overflow-hidden w-full",
        !renderButton &&
          "border border-slate-200 p-1 py-2 dark:border-[#1F242F] bg-white dark:bg-[#0C111D] rounded-md",
        className
      )}
      {...props}
    >
      {selected.length > 0 && (
        <div className={cn("flex flex-wrap gap-1 px-1", valuesClassName)}>
          {selected.map((item, index) => (
            <Badge
              key={item.value}
              className={cn(
                "px-1 rounded-xl flex items-center gap-1 !bg-pink",
                activeIndex === index && "ring-2 ring-muted-foreground"
              )}
              variant={"secondary"}
            >
              <span className="text-xs min-w-max font-medium">
                {item.label}
              </span>
              <button
                aria-label={`Remove ${item} option`}
                aria-roledescription="button to remove option"
                type="button"
                onMouseDown={mousePreventDefault}
                onClick={() => onSelectionChange(item)}
              >
                <span className="sr-only">Remove {item.label} option</span>
                <RemoveIcon className="h-4 w-4" />
              </button>
            </Badge>
          ))}
        </div>
      )}
      {renderButton ? (
        <Button
          variant="outline"
          className="w-full"
          onClick={() => setOpen(true)}
        >
          <SlidersHorizontal size={14} />
          Filter
        </Button>
      ) : (
        children
      )}
    </div>
  );
});

MultiSelectTrigger.displayName = "MultiSelectTrigger";

const MultiSelectInput = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => {
  const { setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } =
    useMultiSelect();
  return (
    <CommandPrimitive.Input
      {...props}
      ref={ref}
      value={inputValue}
      onValueChange={activeIndex === -1 ? setInputValue : undefined}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      className={cn(
        "ml-2 bg-transparent text-base outline-none placeholder:text-slate-500 dark:placeholder:text-slate-400 flex-1 disabled:opacity-50",
        className,
        activeIndex !== -1 && "caret-transparent"
      )}
    />
  );
});

MultiSelectInput.displayName = "MultiSelectInput";

const MultiSelectContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children }, ref) => {
  const { open, renderButton } = useMultiSelect();
  return (
    <div
      ref={ref}
      className={cn(
        "min-w-60",
        renderButton ? "absolute top-full right-0" : "relative"
      )}
    >
      {open && children}
    </div>
  );
});

MultiSelectContent.displayName = "MultiSelectContent";

const MultiSelectList = forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, children }, ref) => {
  return (
    <CommandList
      ref={ref}
      className={cn(
        "p-2 flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full absolute bg-background shadow-md z-10 border border-slate-200 bg-white dark:border-[#1F242F] dark:bg-[#0C111D] top-0",
        className
      )}
    >
      {children}
      <CommandEmpty>
        <span className="text-muted-foreground">No results found</span>
      </CommandEmpty>
    </CommandList>
  );
});

MultiSelectList.displayName = "MultiSelectList";

const MultiSelectItem = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  Omit<
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>,
    "value"
  > & {
    value: ValueLabel;
  }
>(({ className, value, children, ...props }, ref) => {
  const {
    selected: Options,
    onSelectionChange,
    setInputValue,
  } = useMultiSelect();

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const isIncluded =
    Options.findIndex((item) => item.value === value.value) !== -1;
  return (
    <CommandItem
      ref={ref}
      onSelect={() => {
        onSelectionChange(value);
        setInputValue("");
      }}
      {...props}
      className={cn(
        "rounded-md cursor-pointer px-2 py-1.5 transition-colors flex gap-2",
        className,
        isIncluded && "opacity-50",
        props.disabled && "opacity-50 cursor-not-allowed"
      )}
      onMouseDown={mousePreventDefault}
    >
      {children}
      {isIncluded && <Check className="h-4 w-4" />}
    </CommandItem>
  );
});

MultiSelectItem.displayName = "MultiSelectItem";

export {
  MultiSelect,
  MultiSelectTrigger,
  MultiSelectInput,
  MultiSelectContent,
  MultiSelectList,
  MultiSelectItem,
};
