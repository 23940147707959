import { Card, CardDescription, CardHeader, CardTitle } from "../card";

const CustomTooltip = (props) => {
  const { active, payload, label, name, type, labelFormatter } = props;
  if (!active || !payload || payload.length === 0) return <></>;
  const description = type === "pie" ? name || payload[0].name : label;
  const descriptionWithFormat = description
    ? labelFormatter
      ? labelFormatter(description)
      : description
          .toString()
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(" ")
    : "";
  return (
    <div className="flex flex-col gap-3">
      {payload.map((data, k, arr) => (
        <Box
          payload={data}
          description={descriptionWithFormat}
          isOnly={arr.length === 1}
          type={type}
          key={`tooltip-${k}`}
        />
      ))}
    </div>
  );
};

const Box = ({ payload, description, type }) => {
  return (
    <Card className="p-4 gap-2">
      <CardHeader className="p-0 text-left flex-row items-center gap-2 space-y-0">
        {(type === "pie" || type === "multi-line") && (
          <div
            style={{
              backgroundColor:
                type === "pie" ? payload.payload.fill : payload.stroke,
            }}
            className="h-2 w-2 rounded-full"
          ></div>
        )}
        <CardDescription>
          {type === "multi-line" || type === "pie" || type === "bar"
            ? payload.name
            : description}
        </CardDescription>
      </CardHeader>
      <CardTitle>{payload.value}</CardTitle>
    </Card>
  );
};

export default CustomTooltip;
