import { useState } from "react";
import Button from "../../components/Button";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SEARCH_OPTIONS, clusterIcons } from "../../const/search";
import ObjectRef from "../../components/tile-items/ObjectRef";
import CustomMarker from "../../components/map/CustomMarker";
import Map from "../../components/map/Map";
import { InfoWindowF, MarkerClustererF } from "@react-google-maps/api";
import NoMapResultsIcon from "../../const/search/imgs/no-map-results";
import EyeSlashIcon from "../../const/search/imgs/eye-slash";
import useUsernameSearch from "../../hooks/search/useUsernameSearch";
import InfoWindowContent from "../../components/tile-items/InfoWindowContent";
import LoadingBar from "react-top-loading-bar";
import UsernameRef from "../../components/search-username/UsernameRef";
import MapsProvider from "../../providers/MapsProvider";

const allModules = SEARCH_OPTIONS.find((item) => item.title === "Social Media")
  .options.filter((opt) => opt.searchUsername)
  .map((item) => ({
    label: item.label,
    value: item.value,
    icon: item.icon,
  }));

export default function SearchUsername() {
  const [infoWindow, setInfoWindow] = useState(null);
  const [input, setInput] = useState("");
  const [module, setModule] = useState(null);
  const {
    allUsernames,
    currentResultsData,
    status,
    results,
    handleUsernameSearch,
    handleSearch,
    handleDelete,
  } = useUsernameSearch(input, module);

  return (
    <section className="h-full flex flex-col gap-8 w-full px-8 pb-8">
      <h1 className="text-xl text-[var(--text-color)] text-center font-medium">
        Search by username across social media
      </h1>
      <form
        onSubmit={handleSearch}
        className="gap-4 grid  grid-cols-2 md:items-center md:grid-cols-[1fr_3in_max-content]"
      >
        <div className="relative flex items-center col-span-2 md:col-span-1">
          <FontAwesomeIcon
            width={12}
            className="absolute left-4 text-[#DEEBFF] dark:text-[#376187]"
            icon={faSearch}
          />
          <input
            className="!text-[var(--text-color)] pl-10 w-full rounded-sm border-[1px] px-5 py-2.5 !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187] text-sm placeholder:text-[var(--input-color)] !outline-none"
            type="text"
            placeholder="Username"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
        <ReactSelect
          options={allModules}
          onChange={(opt) => setModule(opt.value)}
          isSearchable={false}
          placeholder="Choose data source"
          unstyled
          value={allModules.find((item) => item.value === module)}
          classNames={{
            container: () =>
              "text-sm text-[var(--primary-color2)] rounded-sm border-[1px] !bg-white dark:!bg-[var(--primary-color-light)] border-[#DEEBFF] dark:border-[#376187]",
            control: () => "px-4",
            option: () =>
              `text-sm text-[var(--primary-color2)] group !bg-white cursor-pointer dark:!bg-[var(--primary-color-light)] px-4 py-2 hover:!bg-[var(--primary-color2)] dark:hover:!bg-[var(--primary-color2)]`,
          }}
          getOptionLabel={({ icon, label }) => {
            const Icon = icon;
            return (
              <div className="flex items-center gap-2 item-styled">
                {icon &&
                  (typeof icon === "function" ? (
                    <div
                      className={`fill-[var(--primary-color2)] group-hover:fill-[var(--primary-color-light)] w-4`}
                    >
                      <Icon />
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      className="w-4 text-[var(--primary-color2)] group-hover:text-[var(--primary-color-light)]"
                      size="lg"
                      icon={icon}
                    />
                  ))}
                <span className="text-sm text-[var(--primary-color2)] group-hover:text-[var(--primary-color-light)]">
                  {label}
                </span>
              </div>
            );
          }}
        />
        <div className="hover:opacity-80 transition-opacity">
          <Button
            reverseFill
            preventHover
            icon={faSearch}
            className="!w-full !px-16"
            disabled={status.isLoading}
          >
            Search
          </Button>
        </div>
      </form>
      {allUsernames.length > 0 && (
        <div className="flex items-center gap-4 flex-wrap">
          {allUsernames.map((item) => (
            <UsernameRef
              data={item}
              isActive={currentResultsData.username === item.username}
              handleSearch={(e) => {
                e.preventDefault();
                setModule(item.type);
                setInput(item.username);
                handleUsernameSearch({
                  username: item.username,
                  type: item.type,
                });
              }}
              handleDelete={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(item);
              }}
              isDisabled={status.isLoading}
              key={item.username}
            />
          ))}
        </div>
      )}
      <div className="flex flex-col gap-4 bg-[var(--sidebar-color)] rounded p-6">
        <h3 className="text-[var(--text-color)] font-medium">Map</h3>
        <div className="h-full w-full min-h-[4in] relative grid grid-cols-1">
          {results.length > 0 && currentResultsData.module ? (
            <MapsProvider>
              <Map zoom={10} center={results[0].location}>
                <MarkerClustererF
                  options={{
                    styles: [
                      {
                        backgroundPosition: "3% -14%",
                        height: 74,
                        textColor: "#FFFFFF",
                        width: 74,
                        url: clusterIcons[currentResultsData.module],
                      },
                    ],
                  }}
                >
                  {(clusterer) =>
                    results.map((obj, index) => (
                      <CustomMarker
                        {...obj}
                        position={obj.location}
                        clusterer={clusterer}
                        type={currentResultsData.module}
                        onMouseOver={() => setInfoWindow(index)}
                        key={index}
                      >
                        {infoWindow === index && (
                          <InfoWindowF
                            position={obj.location}
                            onCloseClick={() => setInfoWindow(null)}
                          >
                            <InfoWindowContent
                              obj={{ ...obj, type: currentResultsData.module }}
                              prioritize={["url"]}
                            />
                          </InfoWindowF>
                        )}
                      </CustomMarker>
                    ))
                  }
                </MarkerClustererF>
              </Map>
            </MapsProvider>
          ) : (
            <div className="flex flex-col items-center gap-2 self-center justify-self-center text-[var(--text-color)]">
              <div className="stroke-[var(--text-color)] opacity-80 mb-2">
                <NoMapResultsIcon />
              </div>
              <h3 className="text-2xl font-medium">No results</h3>
              <p className="text-lg opacity-80">
                Please add a new item in the search bar
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <h2 className="text-[var(--text-color)] font-medium">
          Results{" "}
          <span className="text-[var(--text-color)] font-normal opacity-80">
            {`(${results.length})`}
          </span>
        </h2>
        {results.length > 0 ? (
          <div className="flex flex-col gap-6 sm:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))]">
            {results.map((result, index) => (
              <ObjectRef
                {...result}
                showTypeIcon
                type={currentResultsData.module}
                key={`result:${index}`}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 self-center justify-self-center text-[var(--text-color)]">
            <div className="stroke-[var(--text-color)] opacity-80 mb-2">
              <EyeSlashIcon />
            </div>
            <h3 className="text-2xl font-medium">No results</h3>
            <p className="text-lg opacity-80">
              Please add a new item in the search bar
            </p>
          </div>
        )}
      </div>
      <LoadingBar height={8} color="#ff00cd" progress={status.progress} />
    </section>
  );
}
