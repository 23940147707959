import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

async function getUploadedItems(
  { from_upload }: Pick<Area, "from_photo" | "from_upload">,
  workspace_id: number
) {
  const { data } = await axios.get(
    `/${
      from_upload ? "getUploadedItems" : "getGeolocatedPhotos"
    }/${workspace_id}`
  );
  return data;
}

const useUploadedItems = (
  workspace_id: number,
  from?: Pick<Area, "from_photo" | "from_upload">,
  config?: SWRConfiguration
) =>
  useSWR(
    from && Object.values(from).some((v) => !!v)
      ? ["uploaded-items", from, workspace_id]
      : null,
    ([_k, from, workspace_id]) => getUploadedItems(from, workspace_id),
    {
      ...config,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export default useUploadedItems;
