import useFilters from "@/providers/search/filters";
import { ScrollArea } from "../ui/scroll-area";
import { Link } from "react-router-dom";
import { LinkIcon } from "lucide-react";

type Props = {
  isLoading: boolean;
};

export default function Newspapers({ isLoading }: Props) {
  const { newspapers } = useFilters();
  return (
    <div className="bg-[var(--body-color)] text-[var(--text-color)] min-w-48 flex-1 flex flex-col border border-t-0 border-slate-200 dark:border-[#1F242F] py-4">
      <h4 className="text-lg text-font font-medium mb-2 px-4">Newspapers</h4>
      {isLoading ? (
        <div className="flex-1 grid place-content-center py-4">
          <l-waveform size={24} stroke={2} color="var(--text-color)" />
        </div>
      ) : newspapers.length > 0 ? (
        <ScrollArea className="h-48 px-4">
          {newspapers.map((newspaper) => (
            <NewspaperRef {...newspaper} key={newspaper.id} />
          ))}
        </ScrollArea>
      ) : (
        <div className="flex-1 justify-center items-center flex">
          <p className="text-center text-base text-font opacity-80">
            No results found
          </p>
        </div>
      )}
    </div>
  );
}

const NewspaperRef = ({ name, url, language }: Newspaper) => (
  <Link
    to={url}
    rel="noreferrer"
    target="_blank"
    className="border-b last:border-b-0 border-slate-200 dark:border-[#1F242F] rounded-sm py-2 border-border flex gap-2 justify-between items-center"
  >
    <h5 className="text-base line-clamp-1 text-font">{name}</h5>
    <h6 className="text-sm opacity-75 text-right line-clamp-1 text-font">
      {language}
    </h6>
  </Link>
);
