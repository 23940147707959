import { SquareArrowOutUpRight } from "lucide-react";
import { Button } from "../ui/button";
import Toast from "../Toast";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import isImgUrl from "@/utils/isImgUrl";

type Props = {
  item: Record<string, any>;
  handleGeolocate: () => void;
  searchNearby: () => void;
  handleFaceSearch: () => void;
  isLoading: {
    geolocation: boolean;
    faceSearch: boolean;
    nearby: boolean;
  };
  isDisabled: {
    faceSearch: boolean;
    nearby: boolean;
  };
};

export default function Actions({
  item,
  handleFaceSearch,
  isDisabled,
  isLoading,
  searchNearby,
  handleGeolocate,
}: Props) {
  const { type, item_id } = useParams();
  const { workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [isVideo, setIsVideo] = useState<boolean>();

  async function handleExport() {
    const loading = toast.custom((t) => (
      <Toast
        isLoading
        {...t}
        title="Loading document in PDF..."
        subtitle="The document is being converted to PDF format"
      />
    ));
    const url =
      `${process.env.REACT_APP_API_SERVER_URL}/api/export-pdf/` +
      type +
      "/" +
      workspace_id +
      "/" +
      item_id;
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          //            "Access-Control-Allow-Credentials": "true",
          "Content-Disposition": "attachment; filename=" + type + ".pdf",
          "Access-Control-Allow-Headers": "Authorization", // Required for cookies, authorization headers with HTTPS
        },
        // @ts-ignore
        responseType: "blob", // Specify the expected response type as blob
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = type + "_" + item_id + ".pdf";
        a.click();

        window.URL.revokeObjectURL(url);
        window.setTimeout(() => toast.dismiss(loading), 9000);
      } else {
        const data = await response.json();
        toast.dismiss(loading);
        data.error &&
          toast.custom((t) => (
            <Toast {...t} isError title="Error" subtitle={data.message} />
          ));
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  }

  useEffect(() => {
    if (!item.photo_url) return setIsVideo(false);
    (async () => {
      const isImage = await isImgUrl(item.photo_url);
      setIsVideo(!isImage);
    })();
  }, [item.photo_url]);

  return (
    <div className="max-h-max mt-auto">
      <div className="flex flex-wrap items-center gap-3 mt-3">
        <Button asChild className="h-9">
          <Link
            to={item.url}
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
          >
            <SquareArrowOutUpRight size={14} />
            Visit URL
          </Link>
        </Button>
        {isVideo === false && (
          <Button
            onClick={handleExport}
            className="text-font"
            variant="outline"
          >
            Export PDF
          </Button>
        )}
        {(item.photo_url || item.screenshot) && (
          <Button
            onClick={handleGeolocate}
            disabled={isLoading.geolocation}
            variant="outline"
            className="text-font"
          >
            {isLoading.geolocation && (
              <l-waveform color="var(--text-color)" size={14} stroke={1.5} />
            )}
            AI Geolocate
          </Button>
        )}
        {item.photo_url && isVideo === false && (
          <Button
            onClick={handleFaceSearch}
            disabled={isLoading.faceSearch || isDisabled.faceSearch}
            variant="outline"
            className="text-font"
          >
            {isLoading.faceSearch && (
              <l-waveform color="var(--text-color)" size={14} stroke={1.5} />
            )}
            Face Search
          </Button>
        )}
        <Button
          onClick={searchNearby}
          className="text-font"
          variant="outline"
          disabled={isLoading.nearby || isDisabled.nearby}
        >
          {isLoading.nearby && (
            <l-waveform color="var(--text-color)" size={14} stroke={1.5} />
          )}
          Search nearby
        </Button>
      </div>
    </div>
  );
}
