import { Link } from "react-router-dom";

type Props = {
  news: News[];
  isLoading?: boolean;
};

export default function NewsPopup({ news, isLoading }: Props) {
  return (
    <div className="w-full absolute top-full left-0 right-0">
      <div className="bg-[var(--body-color)] text-[var(--text-color)] border border-t-0 border-slate-200 dark:border-[#1F242F]">
        {isLoading ? (
          <div className="flex-1 grid place-content-center py-4">
            <l-waveform size={24} stroke={2} color="var(--text-color)" />
          </div>
        ) : (
          <div className="flex flex-col max-h-[20rem] overflow-y-auto overflow-x-hidden px-4 py-2">
            {news
              .filter((item) => item.url)
              .map((item) => (
                <NewsRef {...item} key={`news:${item.id}`} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

const NewsRef = ({
  title,
  summary,
  photo_url,
  author,
  url,
  timestamp,
  category,
}: News) => (
  <Link to={url} target="_blank" rel="noreferrer">
    <div className="gap-y-1 gap-x-4 grid grid-cols-[1fr_3fr] rounded py-2">
      <div className="row-span-4 rounded overflow-hidden relative">
        <img
          className="w-full h-full object-cover absolute inset-0"
          src={photo_url}
          alt=""
        />
      </div>
      <div className="text-[var(--primary-color2)] flex items-center justify-between gap-4 text-sm opacity-80 pt-2">
        <span>{new Date(timestamp).toLocaleDateString()}</span>
        <span>{category}</span>
      </div>
      <p className="text-[var(--text-color)] text-base font-semibold">
        {title}
      </p>
      <p className="text-[var(--text-color)] opacity-80 text-sm leading-normal line-clamp-3">
        {summary}
      </p>
      <p className="pb-2 text-[var(--text-color)] font-medium text-sm leading-normal mt-1">
        {author}
      </p>
    </div>
  </Link>
);
