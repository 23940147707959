import axios from "axios";
import { format } from "date-fns";
import useSWR, { SWRConfiguration } from "swr";

async function getDailyDashboard(
  workspace_id: string,
  date: string
): Promise<DailyDashboardResponse> {
  const { data } = await axios.post(`/getDailyDashboard/${workspace_id}`, {
    date,
  });
  if (data.error) {
    throw new Error(data.message || "Unknown error occurred");
  }
  return data;
}

const useDailyDashboard = (
  workspace_id: string,
  date: Date,
  config?: SWRConfiguration
) =>
  useSWR(
    ["daily-dashboard", workspace_id, format(date, "yyyy-MM-dd")],
    ([_k, wId, d]) => getDailyDashboard(wId, d),
    config
  );

export default useDailyDashboard;
