import useFilters from "@/providers/search/filters";
import { ScrollArea } from "../ui/scroll-area";
import { Pause, Play, Volume2, VolumeX, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { Slider } from "../ui/slider";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

type Props = {
  isLoading: boolean;
};

export default function Radio({ isLoading }: Props) {
  const mapContainer = document.getElementById("map-container");
  const [selectedRadio, setSelectedRadio] = useState<RadioResult | null>(null);
  const { radios } = useFilters();

  return (
    <>
      <div className="bg-[var(--body-color)] flex-1 text-[var(--text-color)] min-w-48 flex flex-col border border-t-0 border-slate-200 dark:border-[#1F242F] py-4">
        <h4 className="text-lg text-font font-medium mb-2 px-4">Radio</h4>
        {isLoading ? (
          <div className="flex-1 grid place-content-center py-4">
            <l-waveform size={24} stroke={2} color="var(--text-color)" />
          </div>
        ) : radios.length > 0 ? (
          <ScrollArea className="h-48 px-4">
            {radios.map((radio) => (
              <RadioRef
                {...radio}
                isPlaying={selectedRadio?.id === radio.id}
                onPlay={() => setSelectedRadio(radio)}
                key={radio.id}
              />
            ))}
          </ScrollArea>
        ) : (
          <div className="flex-1 justify-center items-center flex">
            <p className="text-center text-base text-font opacity-80">
              No results found
            </p>
          </div>
        )}
      </div>
      {mapContainer &&
        selectedRadio &&
        createPortal(
          <RadioBar
            {...selectedRadio}
            onClose={() => setSelectedRadio(null)}
          />,
          mapContainer
        )}
    </>
  );
}

export const RadioBar = ({
  url_resolved,
  photo_url,
  url,
  name,
  onClose,
}: RadioResult) => {
  const [volume, setVolume] = useState(100);
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    const audio = new Audio(url_resolved);
    audio.onplay = () => setPlaying(true);
    audio.onpause = () => setPlaying(false);
    audio.play();
    audioRef.current = audio;
    return () => {
      audio.pause();
    };
  }, [url_resolved]);

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.volume = volume / 100;
  }, [volume]);

  return (
    <div className="absolute w-full border-t border-slate-200 dark:border-[#1F242F] bottom-0 z-30 bg-[var(--body-color)] inset-x-0 p-4 pr-8">
      <div className="flex items-center gap-4 justify-between">
        <div className="flex items-center gap-4">
          <img
            className="w-16 h-16 rounded-md object-cover"
            src={photo_url}
            alt=""
          />
          <Link
            to={url}
            target="_blank"
            rel="noreferrer"
            className="text-font font-medium text-lg"
          >
            {name}
          </Link>
        </div>
        <button
          onClick={() =>
            audioRef.current &&
            (audioRef.current.paused
              ? audioRef.current.play()
              : audioRef.current.pause())
          }
          className="h-16 w-16 text-font grid place-content-center bg-[var(--sidebar-color)] rounded-full absolute left-1/2 -translate-x-1/2"
        >
          {!playing ? <Play size={24} /> : <Pause size={24} />}
        </button>
        <div className="flex items-center gap-3">
          <Popover>
            <PopoverTrigger className="text-font">
              {volume === 0 ? <VolumeX size={24} /> : <Volume2 size={24} />}
            </PopoverTrigger>
            <PopoverContent className="max-w-max">
              <div className="h-24">
                <Slider
                  value={[volume]}
                  orientation="vertical"
                  step={1}
                  max={100}
                  onValueChange={(value) => setVolume(value[0])}
                />
              </div>
            </PopoverContent>
          </Popover>
          <button
            type="button"
            onClick={onClose}
            className="h-12 w-12 flex items-center justify-center text-font"
          >
            <X size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

const RadioRef = ({ name, isPlaying, onPlay }: RadioResult) => (
  <div className="border-b last:border-b-0 border-slate-200 dark:border-[#1F242F] rounded-sm py-2 border-border flex gap-2 justify-between items-center">
    <h5 className="text-base text-font">{name}</h5>
    <button onClick={onPlay}>
      {isPlaying ? <Pause size={14} /> : <Play size={14} />}
    </button>
  </div>
);
