import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Card, CardContent, CardFooter, CardTitle } from "../ui/card";
import { Textarea } from "../ui/textarea";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import Toast from "../Toast";

type Props = {
  defaultValue: string;
  onChange: (note: string) => void;
};

export default function Notes({ defaultValue, onChange }: Props) {
  const { workspace_id } = useParams();
  const { type, item_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setInput(defaultValue);
  }, [defaultValue]);

  async function onClose() {
    if (defaultValue === input) {
      setIsOpen(false);
      return;
    }
    setIsLoading(true);
    const request_data = {
      coordinates_id: Number(workspace_id),
      item_id: Number(item_id),
      note: input,
      type: type,
    };
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/save_note/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request_data),
        }
      );
      const data = await response.json();
      toast.custom((t) => <Toast {...t} isSuccess title={`${data.message}`} />);
      onChange(input);
      setIsOpen(false);
    } catch (e) {
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Failed to save the message"
          subtitle={(e as Error).message}
        />
      ));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="fixed bottom-8 right-4 md:right-8">
      {isOpen ? (
        <Card>
          <CardTitle>Notes</CardTitle>
          <CardContent className="py-2">
            <Textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="min-w-80 min-h-64 bg-white dark:bg-[#161B26] border-slate-200 dark:border-[#333741]"
              placeholder="Note something here"
            ></Textarea>
          </CardContent>
          <CardFooter>
            <Button
              disabled={isLoading}
              onClick={onClose}
              variant="outline"
              className="text-font w-full h-9"
            >
              {isLoading ? (
                <l-waveform
                  size={16}
                  stroke={2}
                  color="var(--text-color)"
                ></l-waveform>
              ) : input === defaultValue ? (
                "Close"
              ) : (
                "Save & Close"
              )}
            </Button>
          </CardFooter>
        </Card>
      ) : (
        <Button
          onClick={() => setIsOpen(true)}
          className="w-12 h-12 rounded-full"
        >
          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6673 8.75033V5.66699C14.6673 4.26686 14.6673 3.5668 14.3948 3.03202C14.1552 2.56161 13.7727 2.17916 13.3023 1.93948C12.7675 1.66699 12.0674 1.66699 10.6673 1.66699H5.33398C3.93385 1.66699 3.23379 1.66699 2.69901 1.93948C2.2286 2.17916 1.84615 2.56161 1.60647 3.03202C1.33398 3.5668 1.33398 4.26686 1.33398 5.66699V14.3337C1.33398 15.7338 1.33398 16.4339 1.60647 16.9686C1.84615 17.439 2.2286 17.8215 2.69901 18.0612C3.23379 18.3337 3.93385 18.3337 5.33398 18.3337H8.00065M9.66732 9.16699H4.66732M6.33398 12.5003H4.66732M11.334 5.83366H4.66732M13.0007 17.5003V12.5003M10.5007 15.0003H15.5007"
              stroke="currentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      )}
    </div>
  );
}
