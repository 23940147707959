import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Accordion,
} from "@/components/ui/accordion";
import TypeAccordion from "./accordion";
import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";

export default function Transportation({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <AccordionItem className="px-4" value="transportation">
      <AccordionTrigger>
        <div />
        <span>Transportation</span>
        <span className="opacity-60 font-normal">
          {data?.statistics.transportation || "0"}
        </span>
      </AccordionTrigger>
      <AccordionContent>
        {data?.objects.transportation &&
        data.objects.transportation.length > 0 ? (
          <Accordion collapsible type="single">
            <TypeAccordion
              name="vessel"
              title="Vessels"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "vessel"
              )}
            />
            <TypeAccordion
              name="planes"
              title="Planes"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "planes"
              )}
            />
            <TypeAccordion
              name="waze"
              title="Waze"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "waze"
              )}
            />
            <TypeAccordion
              name="amtrak"
              title="Amtrak"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "amtrak"
              )}
            />
            <TypeAccordion
              name="incidents"
              title="Incidents"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "incidents"
              )}
            />
            <TypeAccordion
              name="police"
              title="Police"
              objects={data.objects.transportation.filter(
                (obj) => obj.type === "police"
              )}
            />
          </Accordion>
        ) : (
          <div className="py-2">
            <p className="text-font text-sm opacity-75 text-center">
              No results found
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
