import useDailyDashboard from "@/lib/daily-dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faMoon,
  faSun,
  faWater,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { CloudSun } from "lucide-react";
import { useParams } from "react-router-dom";

export default function Weather({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  const weather =
    !!data?.objects.weather && data.objects.weather.length > 0
      ? data.objects.weather[0]
      : null;
  return (
    <div className="bg-[var(--body-color)] text-[var(--text-color)] py-4 px-6">
      {!weather ? (
        <div className="flex-1 py-4 justify-center items-center flex flex-col gap-2 text-font opacity-80">
          <CloudSun size={18} />
          <p className="text-center text-base">No weather found</p>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-3">
          <div className="flex items-start gap-4 w-full justify-between">
            <div className="flex flex-col">
              <h3 className="text-xl text-font opacity-75 mb-3">Weather</h3>
              <p className="flex items-end gap-2">
                <strong className="text-4xl font-medium leading-none whitespace-nowrap min-w-max">
                  {weather.temp} <span>°</span>
                </strong>
                <span className="opacity-75 text-lg whitespace-nowrap min-w-max">
                  Feels like {weather.feels_like} <span>°</span>
                </span>
              </p>
            </div>
            <div className="grid grid-cols-[minmax(5rem,1fr)_1fr] items-center gap-4">
              <img className="w-full" src={weather.icon} alt="" />
              {/* <div className="flex flex-col mt-1">
              <strong className="text-[48px] font-semibold leading-none">
                {weather.temp} <span>°</span>
              </strong>
              <span className="opacity-80 text-lg">
                Feels like {weather.feels_like} <span>°</span>
              </span>
            </div> */}
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1.5">
                  <FontAwesomeIcon icon={faWind} />
                  <span className="opacity-80 text-lg">
                    {weather.wind_speed} km / h
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <div className="flex items-center gap-0.5">
                    <FontAwesomeIcon width={8} icon={faArrowUp} />
                    <FontAwesomeIcon icon={faSun} />
                  </div>
                  <span className="opacity-80 text-lg">{weather.sunrise}</span>
                </div>
                <div className="flex items-center gap-1.5">
                  <div className="flex items-center gap-0.5">
                    <FontAwesomeIcon width={8} icon={faArrowDown} />
                    <FontAwesomeIcon icon={faSun} />
                  </div>
                  <span className="opacity-80 text-lg">{weather.sunset}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1.5">
              <FontAwesomeIcon icon={faMoon} />
              <span className="opacity-80 text-lg">{weather.moonphase}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <FontAwesomeIcon icon={faWater} />
              <span className="opacity-80 text-lg">{weather.humidity} %</span>
            </div>
            <div className="flex items-center gap-1.5">
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="opacity-80 text-lg">{weather.pressure} hPa</span>
            </div>
          </div>
          <p className="text-sm text-center">{weather.description}</p>
        </div>
      )}
    </div>
  );
}
