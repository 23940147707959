import { useEffect, Fragment } from "react";
import Map from "./Map";
import CustomMarker from "./CustomMarker";
import { MarkerClustererF } from "@react-google-maps/api";
import { clusterIcons } from "../../const/search";
import useMap from "../../hooks/useMap";

type Props = {
  items?: AreaItem[];
  bounds: {
    north: number;
    south: number;
    east: number;
    west: number;
  };
};

export default function DatabaseMapContainer({ items, bounds }: Props) {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;
    const itemsBounds = new google.maps.LatLngBounds();
    if (items && items.length > 0) {
      items.forEach(({ lat, lng }) => itemsBounds.extend({ lat, lng }));
      map.fitBounds(itemsBounds);
    }
  }, [map, bounds, items]);

  return (
    <div className="googlemap flex-1 h-full">
      <Map
        defaultOptions={{
          restriction: {
            latLngBounds: {
              north: bounds.north,
              south: bounds.south,
              west: bounds.west,
              east: bounds.east,
            },
            strictBounds: true,
          },
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        center={new google.maps.LatLngBounds(bounds).getCenter()}
      >
        {items && items.length > 0 && (
          <MarkerClustererF
            options={{
              styles: [
                {
                  backgroundPosition: "3% -14%",
                  height: 74,
                  textColor: "#FFFFFF",
                  width: 74,
                  url: clusterIcons[items[0].type],
                },
              ],
            }}
          >
            {(clusterer) => (
              <Fragment>
                {items.map(({ lat, lng, type }, k) => (
                  <CustomMarker
                    position={{ lat, lng }}
                    clusterer={clusterer}
                    type={type}
                    key={type + k}
                  />
                ))}
              </Fragment>
            )}
          </MarkerClustererF>
        )}
      </Map>
    </div>
  );
}
