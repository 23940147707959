import { SEARCH_OPTIONS } from "@/const/search";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Accordion,
} from "@/components/ui/accordion";
import TypeAccordion from "./accordion";
import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";

export default function SocialMedia({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <AccordionItem className="px-4" value="social_media">
      <AccordionTrigger>
        <div />
        <span>Social Media</span>
        <span className="opacity-60 font-normal">
          {data?.statistics.social_media || "0"}
        </span>
      </AccordionTrigger>
      <AccordionContent>
        {data?.objects["social_media"] &&
        data.objects["social_media"].length > 0 ? (
          <Accordion collapsible type="single">
            {SEARCH_OPTIONS.find((item) => item.title === "Social Media")
              ?.options.filter((opt) =>
                data.objects["social_media"].some(
                  (obj) => obj.type === opt.value
                )
              )
              .map((opt) => (
                <TypeAccordion
                  name={opt.value}
                  title={opt.label}
                  objects={data.objects["social_media"].filter(
                    (obj) => obj.type === opt.value
                  )}
                  key={opt.value}
                />
              ))}
          </Accordion>
        ) : (
          // <div
          //   style={{
          //     gridTemplateColumns: `repeat(auto-fill,minmax(360px,1fr))`,
          //   }}
          //   className="grid gap-2"
          // >
          //   {objects.map((obj) => (
          //     <ObjectRef
          //       {...obj}
          //       showTypeIcon
          //       key={"social-media-" + obj.id}
          //     />
          //   ))}
          // </div>
          <div className="py-2">
            <p className="text-font text-sm opacity-75 text-center">
              No results found
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
