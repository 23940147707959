import transportation from "@/const/daily-dashboard/transportation";
import { SEARCH_OPTIONS } from "@/const/search";
import ExposedCamerasIcon from "@/const/search/imgs/ExposedCamerasIcon";

const crimes = [
  "crimes",
  "crimes2",
  "amber_alerts",
  "events1",
  "events2",
  "events3",
  "events4",
  "events5",
];

export default function getFilterable(data?: DailyDashboardResponse) {
  if (!data?.objects || !data.statistics) return {};
  return {
    ...(data.statistics.social_media > 0
      ? {
          "Social Media":
            SEARCH_OPTIONS.find(
              (item) => item.title === "Social Media"
            )?.options?.filter((opt) =>
              data.objects.social_media.some((obj) => obj.type === opt.value)
            ) || [],
        }
      : {}),
    ...(data.statistics.social_events > 0
      ? {
          "Social Events":
            SEARCH_OPTIONS.find(
              (item) => item.title === "Events"
            )?.options?.filter(
              (opt) =>
                [
                  "protests",
                  "meetup",
                  "eventbrite",
                  "ticketmaster",
                  "facebook_events",
                  "experiences",
                ].includes(opt.value) &&
                data.objects.social_events.some((obj) => obj.type === opt.value)
            ) || [],
        }
      : {}),
    ...(data.statistics.transportation > 0
      ? {
          Transportation: transportation.filter((opt) =>
            data.objects.transportation.some((obj) => obj.type === opt.value)
          ),
        }
      : {}),
    ...(data.statistics.crime_events > 0
      ? {
          Crimes:
            SEARCH_OPTIONS.find(
              (item) => item.title === "Events"
            )?.options?.filter(
              (opt) =>
                crimes.includes(opt.value) &&
                data.objects.crime_events.some((obj) => obj.type === opt.value)
            ) || [],
        }
      : {}),
    ...(data.statistics.cameras > 0
      ? {
          "Public Cameras":
            SEARCH_OPTIONS.find(
              (item) => item.title === "Public Cameras"
            )?.options?.filter((opt) =>
              data.objects.cameras.some((obj) => obj.type === opt.value)
            ) || [],
        }
      : {}),
    ...(data.objects.exposed_devices.some((obj) => obj.type === "screenshots")
      ? {
          "Exposed Cameras": [
            {
              value: "screenshots",
              label: "Screenshots",
              icon: ExposedCamerasIcon,
            },
          ],
        }
      : {}),
  };
}
