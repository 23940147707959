import useDailyDashboard from "@/lib/daily-dashboard";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { useParams } from "react-router-dom";
import { ICON_TYPES } from "@/const/search";
import useMap from "@/hooks/useMap";
import ObjectRef from "../tile-items/ObjectRef";

type Props = {
  date: Date;
};

export default function Timeline({ date }: Props) {
  return (
    <Accordion
      defaultValue={Array.from(Array(24)).map((_, hour) => hour.toString())}
      type="multiple"
    >
      {Array.from(Array(24)).map((_, hour) => (
        <HourRef hour={hour} date={date} key={hour.toString()} />
      ))}
    </Accordion>
  );
}

interface HourProps extends Props {
  hour: number;
}

const HourRef = ({ hour, date }: HourProps) => {
  const { map } = useMap();
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  const hourDate = new Date();
  hourDate.setHours(hour);
  const objects = data?.objects
    ? Object.values(data.objects)
        .flat()
        .filter(
          (obj) =>
            obj.timestamp &&
            new Date(obj.timestamp).getUTCHours() === hour &&
            !["radio", "weather", "news"].includes(obj.type)
        )
    : [];

  if (objects.length === 0) return <></>;

  const onOpen = (value: string) => {
    if (!map || !value) return;
    const [type, id] = value.split("-");
    const marker = objects.find(
      (obj) => obj.type === type && obj.id === parseInt(id)
    );
    if (!marker) return;
    map.panTo(marker.location);
    map.setZoom(20);
  };

  return (
    <AccordionItem className="px-4" value={hour.toString()}>
      <AccordionTrigger className="flex items-center gap-2">
        <span className="text-font opacity-80 text-base">
          {new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
          }).format(hourDate)}
        </span>
        <div className="bg-font opacity-75 rounded-full h-0.5 w-0.5" />
        <span className="text-font opacity-80 text-base">{objects.length}</span>
        <div className="flex-1 mx-2 bg-font opacity-10 h-px rounded-full" />
        <div className="flex"></div>
      </AccordionTrigger>
      <AccordionContent>
        <Accordion type="single" collapsible onValueChange={onOpen}>
          {objects.map((obj) => (
            <ItemRef {...obj} key={`timeline-${obj.type}-${obj.id}`} />
          ))}
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
};

const ItemRef = (obj: SearchObject) => {
  const { id, type, title, name, timestamp } = obj;
  return (
    <AccordionItem value={`${type}-${id}`} className="border-0">
      <AccordionTrigger iconPosition="end" className="py-0">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center relative h-12">
            <div className="flex items-center justify-center h-6 bg-[var(--body-color)] relative z-10">
              <div className="border border-font opacity-20 rounded-full h-2 w-2"></div>
            </div>
            <div className="absolute bg-slate-200 dark:bg-[#1F242F] inset-y-0 h-full w-px"></div>
          </div>
          <div className="h-6 w-6 shrink-0">
            <img
              className="w-full h-full object-cover object-center"
              src={ICON_TYPES[type as keyof typeof ICON_TYPES]}
              alt=""
            />
          </div>
          <div className="flex items-center gap-2">
            <h4 className="max-w-xs text-left line-clamp-1 text-base text-font">
              {title || name}
            </h4>
            <h5 className="text-font opacity-60 text-base min-w-max">
              {new Intl.DateTimeFormat("en-US", {
                timeStyle: "medium",
                timeZone: "UTC",
              }).format(new Date(timestamp))}
            </h5>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <ObjectRef {...obj} />
      </AccordionContent>
    </AccordionItem>
  );
};

// <div className="flex items-center gap-3">
//       <Button size="sm" asChild className="h-8">
//         <Link to={`/${workspace_id}/module/${type}/item/${id}`}>Details</Link>
//       </Button>
//       <Button
//         variant="outline"
//         size="sm"
//         onClick={() => {
//           if (!map) return;
//           map.panTo(location);
//           map.setZoom(20);
//         }}
//       >
//         Zoom on map
//       </Button>
//     </div>
//     </div>
