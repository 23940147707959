import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { getAIGeolocationId, getTaskInfo } from "../../utils/single-item/lib";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export default function useAIGeolocation() {
  const { workspace_id, type, item_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const timer = useRef<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<AIGeolocationDetails | null>(null);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
    null
  );

  async function handleGeolocate() {
    setIsLoading(true);
    const loading = toast.custom(
      (t) => (
        <Toast
          isLoading
          {...t}
          title="Geolocating photo with AI"
          subtitle="Photo is being geolocated with help of AI"
        />
      ),
      { duration: Infinity }
    );
    try {
      const token = await getAccessTokenSilently();
      const { results, error } = await getAIGeolocationId(
        token,
        JSON.stringify({
          workspace_id,
          item_id,
          type,
        })
      );
      if (error) {
        toast.dismiss(loading);
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle={error} />
        ));
        setIsLoading(false);
        return;
      }
      const [{ task_id }] = results;
      timer.current = window.setInterval(async () => {
        const {
          results: [{ result, state }],
        } = await getTaskInfo<AIGeolocationDetails>(token, task_id);
        if (state === "SUCCESS") {
          if (result.error) {
            toast.custom(
              (t) => (
                <Toast {...t} isError title="Error" subtitle={result.error} />
              ),
              { duration: Infinity }
            );
          }
          if (result.results) {
            setDetails(result.results);
          }
        }
        if (state !== "PENDING") {
          clearInterval(timer.current!);
          toast.dismiss(loading);
          setIsLoading(false);
        }
      }, 1000);
    } catch (error) {
      console.error("There was an error!", error);
    }
  }

  const onInfoWindowLoad = useCallback(
    (iw: google.maps.InfoWindow) => setInfoWindow(iw),
    []
  );

  return {
    isLoading,
    details,
    infoWindow,
    onInfoWindowLoad,
    handleGeolocate,
  };
}
