import Categories from "./categories";
import HoursChart from "./hours-chart";
import News from "./news";
import Radios from "./radios";
import Weather from "./weather";

export default function Stats(params: DailyDashboardParams) {
  return (
    <div className="bg-white dark:bg-body grid grid-cols-[2fr_1fr_1fr] border-t dark:border-[#1F242F]">
      <News {...params} />
      <div className="border-l dark:border-[#1F242F] flex flex-col">
        <Weather {...params} />
        <Radios {...params} />
      </div>
      <div className="border-l dark:border-[#1F242F]">
        <HoursChart {...params} />
        <Categories {...params} />
      </div>
    </div>
  );
}
