import axios from "axios";
import useSWR from "swr";

async function getAreas(): Promise<Area[]> {
  const { data } = await axios.get("/getCoordinates/");
  if (data.error) {
    throw new Error(data.message);
  }
  return data;
}

const useAreas = () => useSWR("areas", getAreas);

export default useAreas;
