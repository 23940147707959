import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";

export default function Categories({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  const stats = data?.statistics
    ? Object.entries(data.statistics).filter(([k]) => !["radio"].includes(k))
    : [];
  const greaterThanZero = data?.statistics
    ? stats
        .filter(([k]) => data.statistics[k] > 0)
        .sort((a, b) => b[1] - a[1])
        .map(([k]) => k)
    : [];
  const total = data?.statistics
    ? stats.reduce((prev, [_k, value]) => prev + value, 0)
    : 0;
  return (
    <div className="px-6 py-4 border-t dark:border-[#1F242F] flex flex-col">
      <h3 className="text-xl text-font opacity-75 mb-4">Categories</h3>
      {data?.statistics && (
        <>
          <div className="rounded-full overflow-hidden flex gap-0.5 w-full mb-4">
            {greaterThanZero.map((k) => (
              <div
                className="h-2"
                style={{
                  width: `${(data.statistics[k] / total) * 100}%`,
                  backgroundColor:
                    CATEGORIES_COLORS[k as keyof typeof CATEGORIES_COLORS],
                }}
                key={k}
              ></div>
            ))}
          </div>
          <div className="flex flex-col gap-2">
            {greaterThanZero.map((type) => (
              <LegendRef
                type={type}
                percentage={(data.statistics[type] / total) * 100}
                key={`legend-${type}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const LegendRef = ({
  type,
  percentage,
}: {
  type: string;
  percentage: number;
}) => (
  <div className="flex items-center justify-between gap-2">
    <div className="flex items-center gap-2">
      <div
        className="rounded-sm w-2.5 h-2.5"
        style={{
          backgroundColor:
            CATEGORIES_COLORS[type as keyof typeof CATEGORIES_COLORS],
        }}
      />
      <h5 className="text-font text-base">
        {type
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(" ")}
      </h5>
    </div>
    <span className="text-font text-base opacity-50">
      {percentage.toFixed(2)}%
    </span>
  </div>
);

export const CATEGORIES_COLORS = {
  social_events: "#66B4D3",
  crime_events: "#5A7CF3",
  transportation: "#675FEE",
  social_media: "#8254F3",
  exposed_devices: "#D750EC",
  cameras: "#D56265",
  news: "#F39B36", // A warm orange to represent breaking stories or news flashes
  radio: "#526278",
};
