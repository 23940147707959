import CustomTooltip from "@/components/ui/charts/CustomTooltip";
import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

const formatHour = (hour: number | string) => {
  const d = new Date();
  d.setHours(typeof hour === "string" ? parseInt(hour) : hour);
  d.setMinutes(0);
  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
  }).format(d);
};

export default function HoursChart({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <div className="px-6 py-4">
      <h3 className="text-xl text-font opacity-75 mb-1">All events</h3>
      <p className="mb-2">
        <strong className="text-4xl font-medium text-font">
          {data?.hourly_counts
            ? Object.values(data.hourly_counts).reduce(
                (prev, curr) => prev + curr,
                0
              )
            : 0}
        </strong>
      </p>
      <ResponsiveContainer width="100%" height={120}>
        <LineChart
          data={
            data?.hourly_counts
              ? Object.entries(data.hourly_counts).map(([hour, count]) => ({
                  hour,
                  count,
                }))
              : []
          }
        >
          <Line
            dataKey="count"
            strokeWidth={2}
            dot={false}
            stroke="var(--primary-color2)"
            activeDot={false}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="3 3"
            stroke="var(--text-color)"
            strokeOpacity={0.15}
          />
          <XAxis
            dataKey="hour"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            // interval={4}
            interval="equidistantPreserveStart"
            tick={{ fontSize: 12, fill: "var(--text-color)", opacity: 0.5 }}
            tickFormatter={formatHour}
          />
          <Tooltip
            content={<CustomTooltip labelFormatter={formatHour} />}
          ></Tooltip>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
