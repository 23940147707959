import useSWR from "swr";
import axios from "axios";

async function getArea(id: string): Promise<Area> {
  const { data } = await axios.get(`/getCoordinates/${id}/`);
  if (data.error) {
    throw new Error(data.message);
  }
  return data;
}

const useArea = (id?: string) =>
  useSWR(id ? ["area", id] : null, ([_k, workspace_id]) =>
    getArea(workspace_id)
  );

export default useArea;
