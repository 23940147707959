import { SEARCH_OPTIONS } from "@/const/search";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Accordion,
} from "@/components/ui/accordion";
import TypeAccordion from "./accordion";
import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";

export default function Cameras({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <AccordionItem className="px-4" value="cameras">
      <AccordionTrigger>
        <div />
        <span>Public Cameras</span>
        <span className="opacity-60 font-normal">
          {data?.statistics.cameras || "0"}
        </span>
      </AccordionTrigger>
      <AccordionContent>
        {data?.objects.cameras && data.objects.cameras.length > 0 ? (
          <Accordion collapsible type="single">
            {SEARCH_OPTIONS.find((item) => item.title === "Public Cameras")
              ?.options.filter((opt) =>
                data.objects.cameras.some((obj) => obj.type === opt.value)
              )
              .map((opt) => (
                <TypeAccordion
                  name={opt.value}
                  title={opt.label}
                  objects={data.objects.cameras.filter(
                    (obj) => obj.type === opt.value
                  )}
                  key={opt.value}
                />
              ))}
          </Accordion>
        ) : (
          <div className="py-2">
            <p className="text-font text-sm opacity-75 text-center">
              No results found
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
