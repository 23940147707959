import { MouseEvent, useEffect, useState } from "react";
import Toast from "../../components/Toast";
import toast from "react-hot-toast";
import { getAnalysis, getDrawingManagerOptions } from "../../utils/search/lib";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

type Data = {
  stats: Stats | null;
  places: string[];
  count: Count;
  timeline: SingleRecord[];
};

export default function useAnalysis(
  markers: any[],
  drawingManager: google.maps.drawing.DrawingManager
) {
  const { workspace_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [analyseMarker, setAnalyseMarker] = useState(false);
  const [analyseRectangle, setAnalyseRectangle] =
    useState<google.maps.Rectangle | null>(null);
  const [data, setData] = useState<Data>({
    stats: null,
    places: [],
    count: {
      total: 0,
    },
    timeline: [],
  });

  async function handleAnalyse(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    drawingManager?.setDrawingMode(null);
    const loading = toast.custom((t) => (
      <Toast
        {...t}
        title="Gathering information..."
        subtitle="Loading information about the area and the objects it contains"
        isLoading
      />
    ));
    const bounds = analyseRectangle?.getBounds();
    if (!bounds) return;
    const items = markers
      .flatMap((item) => item.objects)
      .filter((marker) => marker.location && bounds.contains(marker.location));
    const { lat, lng } = bounds.getNorthEast();
    const { lat: lat2, lng: lng2 } = bounds.getSouthWest();
    const input = {
      items,
      workspace_id,
      ne_lat: lat(),
      ne_lng: lng(),
      sw_lat: lat2(),
      sw_lng: lng2(),
    };
    try {
      const token = await getAccessTokenSilently();
      const data = await getAnalysis(token, input);
      if (data.error) {
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle={data.error} />
        ));
      } else {
        data &&
          setData({
            ...data,
            timeline: Array.isArray(data.timeline) ? data.timeline : [],
          });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
    toast.dismiss(loading);
  }

  useEffect(() => {
    if (!analyseMarker || !drawingManager) {
      analyseRectangle?.setMap(null);
      setAnalyseRectangle(null);
      drawingManager?.setDrawingMode(null);
      return;
    }
    const options = getDrawingManagerOptions("#FF00CD", false);
    drawingManager.setOptions(options as any);
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
  }, [analyseMarker, drawingManager, analyseRectangle]);

  return {
    data,
    isLoading,
    analyseMarker,
    analyseRectangle,
    setAnalyseMarker,
    setAnalyseRectangle,
    handleAnalyse,
  };
}
