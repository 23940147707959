import TypeAccordion from "@/components/daily-dashboard/accordion";
import SocialEvents from "@/components/daily-dashboard/social-events";
import SocialMedia from "@/components/daily-dashboard/social-media";
import Stats from "@/components/daily-dashboard/stats";
import Transportation from "@/components/daily-dashboard/transportation";
import CrimeEvents from "@/components/daily-dashboard/crime-events";
import Cameras from "@/components/daily-dashboard/cameras";
import CustomMarker from "@/components/map/CustomMarker";
import Map from "@/components/map/Map";
import { Accordion } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { clusterIcons, SEARCH_OPTIONS } from "@/const/search";
import useDailyDashboard from "@/lib/daily-dashboard";
import useAreas from "@/lib/database/areas";
import useArea from "@/lib/search/area";
import { cn } from "@/utils/cn";
import { InfoWindowF, MarkerClustererF } from "@react-google-maps/api";
import { differenceInMilliseconds, format, parseISO } from "date-fns";
import {
  CalendarIcon,
  ChartNoAxesColumnIncreasing,
  Eye,
  EyeOff,
  XCircle,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Filter from "@/components/search/filter";
import InfoWindowContent from "@/components/tile-items/InfoWindowContent";
import getFilterable from "@/utils/daily-dashboard/get-filterable";
import ModeSwitch from "@/components/daily-dashboard/mode-switch";
import Timeline from "@/components/daily-dashboard/timeline";
import useMap from "@/hooks/useMap";

type Selected = {
  type: string;
  id: number;
};

function findClosestDate(dates: string[]) {
  const now = new Date();

  return dates.reduce((closest, current) => {
    const closestDate = parseISO(closest);
    const currentDate = parseISO(current);

    return Math.abs(differenceInMilliseconds(now, currentDate)) <
      Math.abs(differenceInMilliseconds(now, closestDate))
      ? current
      : closest;
  });
}

export default function DailyDashboard() {
  const navigate = useNavigate();
  const [isTimeline, setIsTimeline] = useState(false);
  const [selected, setSelected] = useState<Selected | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [enabledDates, setEnabledDates] = useState<string[]>([]);
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const [isMapHidden, setIsMapHidden] = useState(false);
  const [statsVisible, setStatsVisible] = useState(false);
  const { map } = useMap();
  const { data, isLoading, mutate, error } = useDailyDashboard(
    workspace_id,
    date
  );

  const availableToFilter = useMemo(() => getFilterable(data), [data]);

  const [showOnly, setShowOnly] = useState<ValueLabel[]>([]);

  useEffect(() => {
    setShowOnly(SEARCH_OPTIONS.flatMap((opt) => opt.options));
    if (isLoading || error) return;
    setEnabledDates(data?.dates || []);
    setShowOnly(Object.values(getFilterable(data)).flat());
    if (!data?.dates) return;
    data.dates.length > 0 &&
      !data.dates.includes(data.date) &&
      setDate(new Date(findClosestDate(data.dates)));
  }, [workspace_id, data, isLoading, error]);

  const {
    data: areas,
    isLoading: areAreasLoading,
    error: areasError,
  } = useAreas();
  const { data: area, isLoading: isAreaLoading } = useArea(workspace_id);

  const { types, objects } = useMemo<{
    types: string[];
    objects: SearchObject[];
  }>(() => {
    if (!data?.objects)
      return {
        types: [],
        objects: [],
      };
    const objects = Object.values(data.objects).flat();
    const types = objects.reduce(
      (prev, { type }) =>
        prev.includes(type) || !showOnly.some((item) => item.value === type)
          ? prev
          : [...prev, type],
      [] as string[]
    );
    return {
      objects,
      types,
    };
  }, [data, showOnly]);

  useEffect(() => {
    if (!map || objects.length === 0) return;
    map.setCenter(objects[0].location);
    map.setZoom(12);
  }, [map, objects]);

  if (error)
    return (
      <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
        <p className="text-red-500 text-center max-w-sm">
          Error: {error.message}
        </p>
        <Button onClick={() => mutate()}>Retry</Button>
      </div>
    );

  return (
    <section
      id="dashboard-container"
      className="h-full transition-all relative flex-1 flex flex-col w-full"
    >
      <div className="flex items-center justify-between px-6 py-4 gap-4">
        <h1 className="text-font flex items-center gap-4 flex-1">
          <span className="opacity-75 min-w-max text-2xl">Dashboard for</span>{" "}
          {!areAreasLoading && !areasError && (
            <Select
              value={workspace_id}
              onValueChange={(value) => {
                navigate(`/${value}/daily-dashboard`);
              }}
            >
              <SelectTrigger className="font-semibold max-w-xs">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {areas &&
                  areas.map((area) => (
                    <SelectItem
                      value={area.id.toString()}
                      key={area.id.toString()}
                    >
                      {area.title}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          )}
          <span className="opacity-75 text-base">at</span>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-[280px] justify-start text-left font-normal dark:border-[#1F242F] dark:bg-[#0C111D]",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? format(date, "PPP") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(_day, selectedDay) => setDate(selectedDay)}
                initialFocus
                disabled={(date) =>
                  !enabledDates.includes(format(date, "yyyy-MM-dd"))
                }
              />
            </PopoverContent>
          </Popover>
        </h1>
        <Button
          size="icon"
          className={cn(
            "border",
            statsVisible ? "border-transparent" : "bg-body border-[#333741]"
          )}
          onClick={() => setStatsVisible((prev) => !prev)}
        >
          <ChartNoAxesColumnIncreasing
            className={cn(!statsVisible && "text-font")}
            size={18}
          />
        </Button>
      </div>
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <l-waveform size={48} color="white" />
        </div>
      ) : (
        !error && (
          <>
            {statsVisible && <Stats date={date} />}
            <div className="border-y dark:border-[#1F242F] px-6 py-4 flex items-center justify-between">
              <h2 className="text-font font-medium text-xl">
                {objects.length} results
              </h2>
              <div className="flex items-center gap-3">
                <Button
                  onClick={() => setIsMapHidden((prev) => !prev)}
                  variant="outline"
                >
                  {isMapHidden ? <Eye size={14} /> : <EyeOff size={14} />}
                  {isMapHidden ? "Show" : "Hide"} Map
                </Button>
                <Filter
                  renderButton
                  availableValues={availableToFilter}
                  selectedValues={showOnly}
                  setSelectedValues={setShowOnly}
                />
                <ModeSwitch
                  isTimeline={isTimeline}
                  setIsTimeline={setIsTimeline}
                />
              </div>
            </div>
            <div
              className={cn(
                "flex-1 grid",
                isMapHidden
                  ? "grid-cols-1"
                  : isTimeline
                  ? "grid-cols-[1fr_2fr]"
                  : "grid-cols-2"
              )}
            >
              {data?.objects ? (
                <div
                  className={cn(
                    "relative h-full w-full",
                    isTimeline && isMapHidden && "max-w-lg mx-auto"
                  )}
                >
                  <div className="absolute inset-0 w-full h-full">
                    <ScrollArea className="relative h-full">
                      {isTimeline ? (
                        <Timeline date={date} />
                      ) : (
                        <Accordion collapsible type="single">
                          <SocialMedia date={date} />
                          <SocialEvents date={date} />
                          <Transportation date={date} />
                          <CrimeEvents date={date} />
                          <Cameras date={date} />
                          <TypeAccordion
                            name="screenshots"
                            title="Exposed Cameras"
                            objects={data?.objects?.exposed_devices || []}
                          />
                        </Accordion>
                      )}
                    </ScrollArea>
                  </div>
                </div>
              ) : (
                <div className="h-full w-full py-4 justify-center items-center flex flex-col gap-2 text-font opacity-80">
                  <XCircle size={18} />
                  <p className="text-center text-base">No results found</p>
                </div>
              )}
              {!isMapHidden && (
                <div>
                  {isAreaLoading ? (
                    <div className="w-full h-full flex items-center justify-center">
                      <l-waveform size={48} color="white" />
                    </div>
                  ) : (
                    area && (
                      <Map
                        onLoad={(map: google.maps.Map) => {
                          if (!area) return;
                          const bounds = {
                            north: area.north_lat,
                            south: area.south_lat,
                            east: area.east_lng,
                            west: area.west_lng,
                          };
                          map.setCenter(
                            new google.maps.LatLngBounds(bounds).getCenter()
                          );
                        }}
                        defaultOptions={{
                          restriction: {
                            latLngBounds: {
                              north: area.north_lat,
                              south: area.south_lat,
                              east: area.east_lng,
                              west: area.west_lng,
                            },
                            strictBounds: true,
                          },
                        }}
                      >
                        {types.map((type, i) => (
                          <MarkerClustererF
                            options={{
                              styles: [
                                {
                                  backgroundPosition: "3% -14%",
                                  height: 74,
                                  textColor: "#FFFFFF",
                                  width: 74,
                                  url: clusterIcons[
                                    type as keyof typeof clusterIcons
                                  ],
                                },
                              ],
                            }}
                            key={`clusterer-${type}-${i}`}
                          >
                            {(clusterer) => (
                              <>
                                {objects
                                  .filter((obj) => obj.type === type)
                                  .map((obj) => (
                                    <CustomMarker
                                      {...obj}
                                      clusterer={clusterer}
                                      position={obj.location}
                                      onClick={() =>
                                        setSelected({
                                          type: obj.type,
                                          id: obj.id,
                                        })
                                      }
                                      key={`marker-${type}-${i}-${obj.id}`}
                                    >
                                      {selected &&
                                        selected.id === obj.id &&
                                        selected.type === obj.type && (
                                          <InfoWindowF
                                            position={obj.location}
                                            onCloseClick={() =>
                                              setSelected(null)
                                            }
                                            key={`info-window-${obj.id}-${obj.type}`}
                                          >
                                            <InfoWindowContent
                                              obj={obj}
                                              key={`info-window-content-${obj.id}-${obj.type}`}
                                            />
                                          </InfoWindowF>
                                        )}
                                    </CustomMarker>
                                  ))}
                              </>
                            )}
                          </MarkerClustererF>
                        ))}
                      </Map>
                    )
                  )}
                </div>
              )}
            </div>
          </>
        )
      )}
    </section>
  );
}
