import { cn } from "@/utils/cn";
import { Dispatch, SetStateAction } from "react";

type Props = {
  isTimeline: boolean;
  setIsTimeline: Dispatch<SetStateAction<boolean>>;
};

export default function ModeSwitch({ isTimeline, setIsTimeline }: Props) {
  return (
    <div className="rounded-lg overflow-hidden border border-slate-300 bg-white dark:bg-[var(--body-color)] dark:border-[#333741] grid grid-cols-[1fr_1px_1fr]">
      <button
        onClick={() => setIsTimeline(false)}
        className={cn(
          "h-10 w-10 grid place-content-center",
          isTimeline
            ? "text-[#98A2B3]"
            : "bg-pink/5 dark:bg-[#1F242F] text-font"
        )}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.75 2.25C0.75 1.85156 1.07812 1.5 1.5 1.5H10.5C10.8984 1.5 11.25 1.85156 11.25 2.25C11.25 2.67188 10.8984 3 10.5 3H1.5C1.07812 3 0.75 2.67188 0.75 2.25ZM0.75 6C0.75 5.60156 1.07812 5.25 1.5 5.25H10.5C10.8984 5.25 11.25 5.60156 11.25 6C11.25 6.42188 10.8984 6.75 10.5 6.75H1.5C1.07812 6.75 0.75 6.42188 0.75 6ZM11.25 9.75C11.25 10.1719 10.8984 10.5 10.5 10.5H1.5C1.07812 10.5 0.75 10.1719 0.75 9.75C0.75 9.35156 1.07812 9 1.5 9H10.5C10.8984 9 11.25 9.35156 11.25 9.75Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <div className="bg-slate-300 dark:bg-[#333741]"></div>
      <button
        onClick={() => setIsTimeline(true)}
        className={cn(
          "h-10 w-10 grid place-content-center",
          !isTimeline
            ? "text-[#98A2B3]"
            : "bg-pink/5 dark:bg-[#1F242F] text-font"
        )}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 2.25C0 1.85156 0.328125 1.5 0.75 1.5H9.75C10.1484 1.5 10.5 1.85156 10.5 2.25C10.5 2.67188 10.1484 3 9.75 3H0.75C0.328125 3 0 2.67188 0 2.25ZM1.5 6C1.5 5.60156 1.82812 5.25 2.25 5.25H11.25C11.6484 5.25 12 5.60156 12 6C12 6.42188 11.6484 6.75 11.25 6.75H2.25C1.82812 6.75 1.5 6.42188 1.5 6ZM10.5 9.75C10.5 10.1719 10.1484 10.5 9.75 10.5H0.75C0.328125 10.5 0 10.1719 0 9.75C0 9.35156 0.328125 9 0.75 9H9.75C10.1484 9 10.5 9.35156 10.5 9.75Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  );
}
