import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import useSearch from "../../hooks/search/useSearch";
import { useAuth0 } from "@auth0/auth0-react";
import { getCoordinates } from "../../utils/search/lib";
import toast from "react-hot-toast";
import Map from "../../components/map/Map";
import useMap from "../../hooks/useMap";
import { MarkerClustererF } from "@react-google-maps/api";
import { clusterIcons } from "../../const/search";
import CustomMarker from "../../components/map/CustomMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive, faSearch } from "@fortawesome/free-solid-svg-icons";
import HashtagRef from "../../components/hashtag-search/ref";
import ObjectRef from "../../components/tile-items/ObjectRef";
import Toast from "../../components/Toast";
import { getCoordinatesItems } from "../../utils/tile-items/lib";
import { useParams } from "react-router-dom";
import Pagination from "../../components/pagination";
import { Input } from "@/components/ui/input";
import { Card, CardDescription, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const sortOptions = [
  { label: "Latest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

const limitOptions = [40, 50, 100, 200];

const getTopHashtags = (arr: string[]): string[] => {
  // Step 1: Count the occurrences of each string
  const countMap: Record<string, number> = {};
  arr.forEach((str) => {
    countMap[str] = (countMap[str] || 0) + 1;
  });

  // Step 2: Sort the strings based on their counts in descending order
  const sortedStrings = Object.keys(countMap).sort(
    (a, b) => countMap[b] - countMap[a]
  );

  // Step 3: Return the top 5 strings
  return sortedStrings.slice(0, 5);
};

const defaultFilter = {
  search: "",
  limit: 40,
  page: 1,
  sort: "newest",
};

export default function HashtagSearch() {
  const { type, workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [input, setInput] = useState("");
  const [area, setArea] = useState<Area | null>(null);
  const { map, fitBounds } = useMap();
  const [filter, setFilter] = useState(defaultFilter);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState({
    page: true,
    filter: false,
  });
  const [showDashboard, setShowDashboard] = useState(false);
  const { loadingRequests, processSearch, markerList, setMarkerList } =
    useSearch(() => setShowDashboard(true));
  const firstRender = useRef(true);

  const objects = markerList.flatMap((item) => item.objects);

  async function handleSearch(hashtag: string) {
    if (!area) return;
    const { lat, lng } = area;
    await processSearch({
      lat,
      lng,
      sw_lat: area.south_lat,
      sw_lng: area.west_lng,
      ne_lat: area.north_lat,
      ne_lng: area.east_lng,
      options: [type],
      keywords: hashtag,
      coordinates_id: Number(workspace_id),
      date_to: null,
    });
  }

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const coordinatesData = await getCoordinates(token, workspace_id);
        setArea(coordinatesData);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [workspace_id, getAccessTokenSilently]);

  useEffect(() => {
    firstRender.current = true;
  }, [type]);

  useEffect(() => {
    // run every time filter changes
    // const isFilterTheSame = Object.entries(defaultFilter).every(
    //   ([key, value]) => filter[key as keyof typeof filter] === value
    // );
    if (!workspace_id || !type) return;
    setIsLoading((prev) => ({ ...prev, filter: true }));
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const filtered = await getCoordinatesItems(
          token,
          workspace_id,
          type,
          filter
        );
        const results = filtered.results as SearchObject[];
        setMarkerList([{ type, objects: results }]);
        firstRender.current &&
          setShowDashboard(results.flatMap((item) => item.objects).length > 0);
        firstRender.current = false;
        setPages(filtered.total_pages);
        setIsLoading({ page: false, filter: false });
      } catch (err) {
        console.error(err);
        toast.custom((t) => (
          <Toast {...t} isError title="There was an error" />
        ));
      }
    })();
  }, [filter, getAccessTokenSilently, workspace_id, setMarkerList, type]);

  useEffect(() => {
    if (!map || markerList.length === 0) return;

    fitBounds(
      markerList
        .flatMap(({ objects }) => objects)
        .map(({ location }) => location)
    );
  }, [map, fitBounds, markerList]);

  const topHashtags = useMemo(
    () =>
      markerList.length > 0
        ? getTopHashtags(
            markerList[0].objects
              ? markerList[0].objects.flatMap((item) =>
                  item.hashtags
                    ? item.hashtags.split(",").map((hash) => hash.trim())
                    : []
                )
              : []
          )
        : [],
    [markerList]
  );

  if (isLoading.page) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <l-waveform size={48} color="white" />
      </div>
    );
  }

  return (
    <section className="h-full overflow-x-hidden overflow-y-auto flex flex-col gap-8 px-4 md:px-8 w-full py-8">
      {showDashboard ? (
        <Fragment>
          <div className="flex flex-col gap-4">
            <h3 className="text-[var(--text-color)] text-xl">
              Search by hashtag
            </h3>
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch(input);
              }}
            >
              <div className="grid grid-cols-[1fr_max-content] gap-4">
                <Input
                  type="search"
                  id="hashtag"
                  value={input}
                  onChange={(event) => setInput(event.target.value)}
                  placeholder="NYC"
                />
                <button
                  disabled={loadingRequests.search || !input}
                  type="submit"
                  className="bg-[var(--primary-color)] rounded max-w-max self-end h-full py-2.5 px-5 text-white text-base disabled:opacity-80 flex items-center gap-2"
                >
                  {loadingRequests.search ? (
                    <l-waveform size={14} color="white" stroke={1} />
                  ) : (
                    <FontAwesomeIcon icon={faSearch} />
                  )}
                  Search
                </button>
              </div>
            </form>
          </div>
          <Card>
            <div className="flex items-center gap-4">
              <CardTitle>Map</CardTitle>
              <CardDescription>
                Location is not accurate, search was done by hashtag
              </CardDescription>
            </div>
            <div className="h-[4in] relative">
              {loadingRequests.search ? (
                <div className="w-full h-full rounded bg-[var(--body-color)] grid place-content-center">
                  <img
                    className="animate-spin"
                    width={48}
                    height={48}
                    src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                    alt=""
                  />
                </div>
              ) : markerList.length > 0 ? (
                <Map zoom={10}>
                  {markerList.map(({ type, objects }, k) => (
                    <MarkerClustererF
                      options={{
                        styles: [
                          {
                            backgroundPosition: "3% -14%",
                            height: 74,
                            textColor: "#FFFFFF",
                            width: 74,
                            url: clusterIcons[
                              type as keyof typeof clusterIcons
                            ],
                          },
                        ],
                      }}
                      key={type + k}
                    >
                      {(clusterer) => (
                        <Fragment>
                          {objects.map((obj) => (
                            <CustomMarker
                              {...obj}
                              clusterer={clusterer}
                              position={obj.location}
                              key={type + obj.id}
                            />
                          ))}
                        </Fragment>
                      )}
                    </MarkerClustererF>
                  ))}
                </Map>
              ) : (
                <div className="flex-1 grid place-content-center">
                  <p className="text-[var(--text-color)] text-lg">
                    Search for a hashtag to display results
                  </p>
                </div>
              )}
            </div>
          </Card>
          <div className="flex flex-col gap-4 md:grid grid-cols-[2fr_1fr_max-content_max-content]">
            <Input
              startContent={<FontAwesomeIcon width={12} icon={faSearch} />}
              type="text"
              placeholder="Search items"
              value={filter.search}
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value }))
              }
            />
            <Select
              value={filter.sort}
              onValueChange={(value) =>
                setFilter((prev) => ({ ...prev, sort: value }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((opt) => (
                  <SelectItem value={opt.value} key={opt.value}>
                    {opt.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              value={filter.limit.toString()}
              onValueChange={(value) =>
                setFilter((prev) => ({ ...prev, limit: parseInt(value) }))
              }
            >
              <SelectTrigger className="min-w-32">
                <SelectValue placeholder="Limit" />
              </SelectTrigger>
              <SelectContent>
                {limitOptions.map((lim) => (
                  <SelectItem value={lim.toString()} key={lim.toString()}>
                    {lim}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <button
              // onClick={handleButtonClick}
              className="py-3 md:py-0 bg-[var(--primary-color)] rounded-sm text-black font-medium px-6 text-sm flex items-center gap-2 justify-center"
            >
              <FontAwesomeIcon icon={faFileArchive} />
              Export CSV
            </button>
          </div>
          <div className="flex items-center gap-4 flex-wrap justify-between">
            <div className="flex items-center gap-2 flex-wrap">
              {topHashtags.length > 0 &&
                topHashtags.map((hashtag, k) => (
                  <HashtagRef
                    hashtag={hashtag}
                    isDisabled={loadingRequests.search}
                    isActive={hashtag === filter.search}
                    onClick={() =>
                      setFilter((prev) => ({
                        ...prev,
                        search: prev.search === hashtag ? "" : hashtag,
                      }))
                    }
                    key={hashtag + k}
                  />
                ))}
            </div>
            <Pagination
              currentPage={filter.page}
              totalPage={pages}
              onPageChange={(page: number) =>
                setFilter((prev) => ({ ...prev, page }))
              }
            />
          </div>
          {isLoading.filter ? (
            <div className="w-full py-16 rounded bg-[var(--body-color)] grid place-content-center">
              <img
                className="animate-spin"
                width={48}
                height={48}
                src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                alt=""
              />
            </div>
          ) : objects.length > 0 ? (
            <div className="flex flex-col md:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))] gap-8 items-start">
              {objects.map((item) => (
                <ObjectRef
                  {...item}
                  authorDataEnabled
                  showTypeIcon
                  key={item.id}
                />
              ))}
            </div>
          ) : (
            <CardDescription className="text-center">
              No results have been found
            </CardDescription>
          )}
          {!isLoading.filter && objects.length > 0 && (
            <Pagination
              currentPage={filter.page}
              totalPage={pages}
              onPageChange={(page: number) =>
                setFilter((prev) => ({ ...prev, page }))
              }
            />
          )}
        </Fragment>
      ) : (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <form
            className="flex flex-col gap-2 w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(input);
            }}
          >
            <h3 className="text-[var(--text-color)] mb-2">Search by hashtag</h3>
            <input
              className="px-3 py-3 rounded text-base !text-[var(--input-font)] !bg-white dark:!bg-[var(--primary-color-light)]"
              type="search"
              id="hashtag"
              value={input}
              onChange={(event) => setInput(event.target.value)}
              placeholder="NYC"
            />
            <div className="flex justify-between gap-2">
              <p className="text-[var(--text-color)] opacity-80 text-base">
                It might take up to 2 minutes to find results
              </p>
              <button
                disabled={loadingRequests.search || !input}
                className="bg-[var(--primary-color)] rounded max-w-max self-end py-2.5 px-5 text-white text-base mt-2 disabled:opacity-80 flex items-center gap-2"
              >
                {loadingRequests.search ? (
                  <l-waveform size={14} color="white" stroke={1} />
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
                Search
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
}
