import { Link } from "react-router-dom";
import DatabaseMapContainer from "../map/DatabaseMapContainer";
import { useContext } from "react";
import StepPopup from "../tutorial/step";
import { TutorialContext } from "../../providers/tutorial";
import MapsProvider from "../../providers/MapsProvider";
import { Card } from "../ui/card";
import { Search } from "lucide-react";
import { Button } from "../ui/button";

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  drawingManager: false,
  streetViewControl: false,
  mapTypeControl: false,
  scaleControl: false,
  keyboardShortcuts: false,
  heading: false,
};

export default function LocationRef({
  isFirstChild,
  setDeletedItem,
  ...props
}) {
  const { step, isFinished, changeStep } = useContext(TutorialContext);

  const bounds = {
    north: props.north_lat,
    south: props.south_lat,
    east: props.east_lng,
    west: props.west_lng,
  };

  const tutorialVisible = isFirstChild && !isFinished && step === 3;

  return (
    <Card
      className={
        props.new ? "border-[var(--pink-color)]" : "border-transparent"
      }
    >
      <div
        className={`flex items-center justify-between relative ${
          tutorialVisible ? "z-20" : ""
        }`}
      >
        <h3 className="text-[var(--text-color)] font-medium transition-colors text-xl">
          {props.title}{" "}
          {props.new ? <span className="new-badge">NEW</span> : ""}
        </h3>
        <div className="relative flex items-center gap-3">
          <Button
            onClick={() => setDeletedItem(props)}
            variant="outline"
            className="text-font h-9"
          >
            Delete
          </Button>
          <Button asChild className="h-9">
            <Link
              to={`/${props.id}/search`}
              className="flex items-center gap-2"
              onClick={() => tutorialVisible && changeStep()}
            >
              <Search size={16} />
              Search
            </Link>
          </Button>
          {tutorialVisible && (
            <StepPopup
              title="Choosing location"
              paragraph="Before you search for an actual object, choose a location you'll want to use for this purpose."
              actionText="Click on the button to choose a location"
              position="top-[130%] left-[130%]"
            />
          )}
        </div>
      </div>
      <div className="w-full h-72 xl:h-96 map-container relative z-10 bg-[var(--sidebar-color)] rounded overflow-hidden">
        <MapsProvider>
          <DatabaseMapContainer
            defaultOptions={defaultMapOptions}
            bounds={bounds}
          />
        </MapsProvider>
      </div>
      {tutorialVisible && (
        <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-10" />
      )}
    </Card>
  );
}
